/**
* This is a script provided by Minnesota State for displaying a chat widget. It is unchanged, except
* for removing <script> tags and making a couple of values dynamic based on the current hostname.
* That way we only need to have one script for both sites.
*
* Salesforce Chat - InfoHub@MinnState.edu
*/

/**
 * Another script is required to load before this one:
 * https://service.force.com/embeddedservice/5.0/esw.min.js
 */

var currentDomain = window.location.hostname;
var referringPageValue;

/**
 * Differentiate between BSU vs NTC. These are the values that were provided by Kristi Karels at
 * Distance MN.
 */
if ( window.location.hostname.search('bemidjistate.edu') >= 0 ) {
	referringPageValue = 'www.bemidjistate.edu';
} else {
	referringPageValue = 'Northwest Technical College';
}

var initESW = function(gslbBaseURL) {
	embedded_svc.settings.displayHelpButton = true; //Or false
	embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

	embedded_svc.settings.defaultMinimizedText = 'Chat'; //(Defaults to Chat with an Expert)
	embedded_svc.settings.disabledMinimizedText = 'Offline'; //(Defaults to Agent Offline)

	//embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
	//embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

	// Settings for Chat
	//embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
		// Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
		// Returns a valid button ID.
	//};
	//embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
	//embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
	//embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

	embedded_svc.settings.enabledFeatures = ['LiveAgent'];
	embedded_svc.settings.entryFeature = 'LiveAgent';

	embedded_svc.init(
		'https://distance-mn.my.salesforce.com',
		'https://distance-mn.my.salesforce-sites.com/liveAgentSetupFlow',
		gslbBaseURL,
		'00DDn00000Dt5Mx',
		'Minnesota_State_InfoHub',
		{
			baseLiveAgentContentURL: 'https://c.la1-core2.sfdc-lywfpd.salesforceliveagent.com/content',
			deploymentId: '572Dn000000ldud',
			buttonId: '573Dn000000Z6F7',
			baseLiveAgentURL: 'https://d.la1-core2.sfdc-lywfpd.salesforceliveagent.com/chat',
			eswLiveAgentDevName: 'Minnesota_State_InfoHub',
			isOfflineSupportEnabled: false
		}
		);
};

if (!window.embedded_svc) {
	var s = document.createElement('script');
	s.setAttribute('src', 'https://distance-mn.my.salesforce.com/embeddedservice/5.0/esw.min.js');
	s.onload = function() {
		initESW(null);
	};
	document.body.appendChild(s);
} else {
	initESW('https://service.force.com');
}

embedded_svc.settings.extraPrechatFormDetails = [
{
	"label": "Preferred First Name",
	"transcriptFields": ["Transcript_First_Name__c"],
	"displayToAgent": true
}, {
	"label": "Preferred Last Name",
	"transcriptFields": ["Transcript_Last_Name__c"],
	"displayToAgent": true
}, {
	"label": "Email - Personal",
	"transcriptFields": ["Transcript_Email__c"],
	"displayToAgent": false
}, {
	"label": "Minnesota County",
	"transcriptFields": ["Transcript_County__c"],
	"displayToAgent": false
}, {
	"label": "Partner",
	"value": "Minnesota State InfoHub Support",
	"displayToAgent": true
}, {
	"label": "ReferringPage",
	"value": referringPageValue,
	"transcriptFields": ["Referring_Website__c"],
	"displayToAgent": true
}, {
	"label": "Subject",
	"displayToAgent": false
}, {
	"label": "Origin",
	"value": "Chat",
	"displayToAgent": false
}, {
	"label": "SendingEmail",
	"value": "Email - Personal",
	"displayToAgent": false
}
];

embedded_svc.settings.extraPrechatInfo = [
{
	"entityName": "Contact",
	"showOnCreate": true,
	"linkToEntityName": "Case",
	"linkToEntityField": "ContactId",
	"saveToTranscript": "ContactId",
	"entityFieldMaps": [
	{
		"isExactMatch": false,
		"fieldName": "FirstName",
		"doCreate": false,
		"doFind": true,
		"label": "Preferred First Name"
	}, {
		"isExactMatch": true,
		"fieldName": "LastName",
		"doCreate": false,
		"doFind": true,
		"label": "Preferred Last Name"
			/*                }, {
			"isExactMatch": false,
			"fieldName": "Sending_Email_Select_Email_Type__c",
			"doCreate": false,
			"doFind": false,
			"label": "SendingEmail"
		}, {
			"isExactMatch": false,
			"fieldName": "Email",
			"doCreate": false,
			"doFind": false,
			"label": "Email - Personal" */
	}, {
		"isExactMatch": true,
		"fieldName": "Email_Personal__c",
		"doCreate": false,
		"doFind": true,
		"label": "Email - Personal"
	}, {
		"isExactMatch": false,
		"fieldName": "MN_Counties__c",
		"doCreate": false,
		"doFind": false,
		"label": "Minnesota County"
	}]
}, {
	"entityName": "Case",
	"showOnCreate": true,
	"saveToTranscript": "CaseId",
	"entityFieldMaps": [
	{
		"isExactMatch": false,
		"fieldName": "Subject",
		"doCreate": true,
		"doFind": false,
		"label": "Subject"
	}, {
		"isExactMatch": false,
		"fieldName": "Partner__c",
		"doCreate": true,
		"doFind": false,
		"label": "Partner"
	},{
		"isExactMatch": false,
		"fieldName": "Origin",
		"doCreate": true,
		"doFind": false,
		"label": "Origin"
	},{
		"isExactMatch":false,
		"fieldName":"Status",
		"doCreate":true,
		"doFind":false,
		"label":"Status"
	}]
}
]




/**
 * Our CUSTOM JS for BSU/NTC.
 *
 * Do some customization to the button once it is added. Some of this works in conjunction with the
 * Back to Top button but we don't load chat on every page. So this customization lives here.
 */
function waitForElm(aSelector) {
    return new Promise(resolve => {
        if (document.querySelector(aSelector)) {
            return resolve(document.querySelector(aSelector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(aSelector)) {
                observer.disconnect();
                resolve(document.querySelector(aSelector));
            }
        });

        // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

/**
 * Modify the original button element. This effects the button that originally loads and once it is
 * closed. The minimized button is an entirely different element.
 *
 * Using this custom function because Distance MN's JS creates the element and it is not available
 * until their JS is done doing its thing.
 */
waitForElm('.embeddedServiceHelpButton .helpButton').then((chatButton) => {
	// Setup the config vars.
	const viewportHeight = window.innerHeight;
	const thresholdDistanceChat = viewportHeight * 1; // This should be less than Back to Top.

	// Add classes to the element we need to animate.
	chatButton.classList.add(
		'm-4',
		'animate',
	);

	// Use MDB's JS API for showing the button.
	const chatButtonShowHide = new Animate(chatButton, {
		animationStart: 'manually',
		animationRepeat: false,
		onEnd: () => {
			if ( false === scrollAmountMetForChat ) {
				// Add d-none to make it not clickable after animating out.
				chatButton.classList.add('d-none');
			}
		}
	});
	chatButtonShowHide.init();

	var scrollAmountMetForChat = true;

	document.addEventListener('scroll', () => {
		// Determine if scroll amount is enough to show the Chat button.
		if (
			false === scrollAmountMetForChat && window.scrollY < thresholdDistanceChat
		) {
			// Show the chat button.
			chatButtonShowHide.changeAnimationType('fade-in-down');
			chatButton.classList.remove('fade-out-up', 'd-none');
			scrollAmountMetForChat = true;
			chatButtonShowHide.startAnimation();
		} else if (
			true === scrollAmountMetForChat && window.scrollY > thresholdDistanceChat
		) {
			// Hide the chat button.
			chatButtonShowHide.changeAnimationType('fade-out-up');
			scrollAmountMetForChat = false;
			chatButton.classList.remove('fade-in-down');
			chatButtonShowHide.startAnimation();
		}
	});
});
